<template>
  <div @mousewheel="scrollBar()">
    <header :class="flag ? '' : 'bannerStyle'">
      <div class="contentWrapper header">
        <div class="logo">
          <h1>大波推</h1>
          <img
            src="../assets/image/logo.png"
            alt=""
            srcset=""
          >
        </div>
        <ul>
          <li>
            <a
              @click="anchorPointClick(0)"
              href="#home"
              :class="plateType === 0 ? 'aLight' : ''"
            >首页</a>
          </li>
          <li>
            <a
              @click="anchorPointClick(1)"
              href="#section2"
              :class="plateType === 1 ? 'aLight' : ''"
            >视频介绍</a>
          </li>
          <li>
            <a
              @click="anchorPointClick(2)"
              href="#section3"
              :class="plateType === 2 ? 'aLight' : ''"
            >产品体验</a>
          </li>
          <li>
            <a
              @click="anchorPointClick(3)"
              href="#section6"
              :class="plateType === 3 ? 'aLight' : ''"
            >功能介绍</a>
          </li>
        </ul>
        <div class="operate">
          <button
            class="btnColor logIn"
            @click="goSaas()"
          >登录</button>
          <button
            class="btnColor open"
            @click="showFrameTostFunc()"
          >开通</button>
        </div>
      </div>
    </header>
    <section class="banner_bg">
      <img
        src="../assets/image/bannerbg.png"
        alt=""
      >
    </section>
    <section
      class="banner"
      id="home"
    >
      <div class="contentWrapper">
        <div class="left">
          <h1>外卖爆单码</h1>
          <img src="../assets/image/title.svg" />
          <p class="subtitle1">外卖行业流量爆增密码<br />助力商家跨入心动时代</p>
          <p class="subtitle2">让10000+的外卖顾客成为你的带货达人，让你的短视频流量暴增千倍！</p>
          <div class="input">
            <input
              v-model="phone"
              type="text"
              placeholder="输入您的手机号"
            >
            <button
              class="btnColor"
              @click="getPhone()"
            >获得免费体验</button>
          </div>
        </div>
        <div class="right">
          <img
            style="position: absolute;"
            src="../assets/image/figure.webp"
            alt=""
          >
          <div class="occlude"></div>
        </div>
      </div>
    </section>
    <section class="data">
      <div class="contentWrapper">
        <div class="city"><span class="num">300+</span><span>城市</span></div>
        <div class="business"><span class="num">3w+</span><span>商家</span></div>
        <div class="newcomer"><span class="num">300%+</span><span>新客</span></div>
        <div class="revenueGrowth"><span class="num">200%+</span><span>收益增长</span></div>
      </div>
    </section>
    <section class="section1">
      <div class="contentWrapper">
        <h1>心动外卖</h1>
        <h1>疫情冲击，平台内卷</h1>
        <h1>餐饮商家痛彻心扉</h1>
        <span class="title">疫情冲击，平台内卷<br />餐饮商家痛彻心扉</span>
        <span class="subTitle">当我们在为餐饮、外卖商家服务过程中，我们一直在倾听。<br>
          我们的初心 ：解决商家最痛的痛点</span>
        <div class="faDiv">
          <div class="subDiv">
            <div><img
                src="../assets/image/icon1.png"
                alt=""
              ></div>
            <span class="faTitle">正式告别 > 平台内卷</span>
            <span class="subTitle">
              让商家获得来自外卖平台之外的流量，如抖音、微信等，告别内卷，回归产品，轻松引流，获得更高质量的顾客以及更低的推广成本。
            </span>
          </div>
          <div class="subDiv">
            <div><img
                src="../assets/image/icon2.png"
                alt=""
              ></div>
            <span class="faTitle">盘活私域 > 轻松裂变</span>
            <span class="subTitle">
              帮助商家更好的管理自己的私域财产、发挥其更高的价值，从你的产品触达、到社群管理，都能标准化的发生“客源裂变”效应。
            </span>
          </div>
          <div class="subDiv">
            <div><img
                src="../assets/image/icon3.png"
                alt=""
              ></div>
            <span class="faTitle">心动链路 > 1秒下单</span>
            <span class="subTitle">
              为商家建立心动链路，从看到您的视频到到达下单页面，仅需1秒钟，不再流失一个对你产品感兴趣的顾客，正式进军“心动外卖”
            </span>
          </div>
        </div>
      </div>
    </section>
    <div
      class="section2"
      id="section2"
    >
      <swiper
        class="swiper contentWrapper"
        :options="section2"
      >
        <swiper-slide class="item">
          <div
            class="phone"
            @click="play(can_play ? 1 : 2)"
          >
            <div
              class="play"
              :style="{opacity: can_play ? .85 : 0}"
            ></div>
            <div class="round"></div>
            <img
              src="../assets/image/iPhoneX.png"
              alt=""
            >
            <!-- <img style="position: absolute;" class="video" src="../assets/image/MaskGroup.png" alt=""> -->
            <div class="videoDiv">
              <video
                ref="videoInfo1"
                class="video"
                src="https://pangxieweijia.wxj360.com/%20video/waimai_720p_02.mp4"
              ></video>
              <!-- 封面图 poster="../assets/image/videofm.png"  -->
            </div>
          </div>
          <div class="count">
            <h1>产品码</h1>
            <h1>抖音视频挂载</h1>
            <h1>外卖</h1>
            <span class="title">开始了解
              <span class="beatCount">[产品码]<br /></span>
              花1分钟，观看演示视频
            </span>
            <span class="subCount">产品码——是附带在你外卖商品中一张卡片上的二维码，用户通过抖音扫码，能够帮助你发送一个视频，并自动吸粉到私域并引导二次消费。</span>
            <p class="subTitle">抖音视频发布</p>
            <span class="subCount">扫码者仅需10秒，即可将您的产品宣传视频发布到抖音，傻瓜式操作，替代人工拍摄>发布流程，高度标准化，轻松落地。</span>
            <p class="subTitle">视频挂载外卖店铺</p>
            <span class="subCount">每一个发出的视频，均自动挂载您的美团店铺，观看时产生兴趣，可立即点击店铺链接进行下单，极大提高下单转化率。</span>
            <div
              class="btnColor"
              @click="play(can_play ? 1 : 2)"
            >{{can_play ? '开始观看' : '暂停观看'}}</div>
            <!-- <div class="btnColor" v-else @click="play(2)">暂停观看</div> -->
          </div>
        </swiper-slide>
        <!-- <swiper-slide class="item">
            <div class="phone">
              <div class="round"></div>
              <img src="../assets/image/iPhoneX.png" alt="">
              <div class="videoDiv">
                <video ref="videoInfo2" class="video" src="https://pangxieweijia.wxj360.com/video/jiaocheng/dapingmu.mp4"></video>
              </div>
            </div>
            <div class="count">
              <span class="title">开始了解
                <span class="beatCount">[产品码]<br/></span>
                花1分钟，观看演示视频
              </span>
              <span class="subCount">产品码——是附带在你外卖商品中一张卡片上的二维码，用户通过抖音扫码，能够帮助你发送一个视频，并自动吸粉到私域并引导二次消费。</span>
              <p class="subTitle">抖音视频发布</p>
              <span class="subCount">扫码者仅需10秒，即可将您的产品宣传视频发布到抖音，傻瓜式操作，替代人工拍摄>发布流程，高度标准化，轻松落地。</span>
              <p class="subTitle">视频挂载外卖店铺</p>
              <span class="subCount">每一个发出的视频，均自动挂载您的美团店铺，观看时产生兴趣，可立即点击店铺链接进行下单，极大提高下单转化率。</span>
              <div class="btnColor" @click="play(2)">开始观看</div>
            </div>
          </swiper-slide> -->
        <div
          class="swiper-pagination2"
          style="text-align: center;line-height: 134px;height: 134px;"
          slot="pagination"
        ></div>
        <div
          style="text-align: center;line-height: 134px;height: 134px;"
          slot="pagination"
        >
          <!-- 在轮播图只有一张的时候，用来占轮播点的位置 -->
        </div>
      </swiper>
    </div>
    <div
      class="section3"
      id="section3"
    >
      <swiper
        class="swiper contentWrapper"
        :options="section3"
      >
        <swiper-slide class="item">
          <!-- 轮播图1 -->
          <div class="left">
            <p class="title">体验神奇的产品码</p>
            <span class="Count">抖音扫一扫，领取3-100元红包，同时还有超级粉丝大礼包，3天有效。</span>
            <span class="subCount">扫一扫下方二维码，即可体验神奇的产品码，一码多能，让您的产品瞬间获得营销能力，无需培训员工，只需在每个外卖中放入一张，轻松落地。</span>
            <div class="productCode">
            </div>
          </div>
          <div class="right">
            <div class="no">
              <div class="arrangement">
                1
              </div>
              <div class="word">
                <span class="title">抖音扫码</span>
                <span class="count">打开抖音，使用扫一扫功能。</span>
              </div>
            </div>
            <span class="vertical"></span>
            <div class="no">
              <div class="arrangement">
                2
              </div>
              <div class="word">
                <span class="title">同意授权并确认发布</span>
                <span class="count">点击授权、点击确认发布视频，即可将视频</span>
              </div>
            </div>
            <span class="vertical"></span>
            <div class="no">
              <div class="arrangement">
                3
              </div>
              <div class="word">
                <span class="title">跳转微信</span>
                <span class="count">自动跳转并唤起微信</span>
              </div>
            </div>
            <span class="vertical"></span>
            <div class="no">
              <div class="arrangement">
                4
              </div>
              <div class="word">
                <span class="title">吸粉与二次营销</span>
                <span class="count">将你预先设置好的吸粉二维码、抖音外卖券、堂食套餐券、会员小程序等一并发送给顾客</span>
              </div>
            </div>
          </div>
          <!-- 轮播图1结尾 -->
        </swiper-slide>
        <swiper-slide class="item">
          <div class="left">
            <p class="title">体验刺激的私域码</p>
            <span class="Count">抖音扫一扫，参与抖音达人挑战赛，同时还可抽取幸运锦鲤大奖。</span>
            <span class="subCount">扫一扫下方二维码，即可体验最刺激的私域码，这有可能是最具性价比又最有趣的的私域短视频推广方式，让您在私域社群做一场成本极低的群内活动，仅需提供少量奖品，即可获得大量短视频转发，因为他确实很有挑战性。</span>
            <div class="privateDomainCode">
            </div>
          </div>
          <div class="right">
            <div class="no">
              <div class="arrangement">
                1
              </div>
              <div class="word">
                <span class="title">抖音扫码打开抖音，使用扫一扫功能</span>
                <!-- <span class="count">打开抖音，使用扫一扫功能。</span> -->
              </div>
            </div>
            <span class="vertical"></span>
            <div class="no">
              <div class="arrangement">
                2
              </div>
              <div class="word">
                <span class="title">同意授权并确认发布点击授权、点击确认发布视频，即可将视频发布到抖音</span>
                <!-- <span class="count">点击授权、点击确认发布视频，即可将视频</span> -->
              </div>
            </div>
            <span class="vertical"></span>
            <div class="no">
              <div class="arrangement">
                3
              </div>
              <div class="word">
                <span class="title">自动进入比赛（锦鲤）活动页面，并获得参赛（抽奖）资格</span>
                <!-- <span class="count">自动跳转并唤起微信</span> -->
              </div>
            </div>
            <span class="vertical"></span>
            <div class="no">
              <div class="arrangement">
                4
              </div>
              <div class="word">
                <span class="title">在活动结束时，一键开奖，并在群内告知客户开奖信息，凭视频领奖，完美杜绝删除视频，同时支持多商家联合壮大奖池，增加活动吸引力</span>
                <!-- <span class="count">将你预先设置好的吸粉二维码、抖音外卖券、堂食套餐券、会员小程序等一并发送给顾客</span> -->
              </div>
            </div>
          </div>
        </swiper-slide>
        <div
          class="swiper-pagination3"
          style="position: absolute;text-align: center;line-height: 157px;height: 157px;z-index: 9;"
          slot="pagination"
        ></div>
      </swiper>
    </div>
    <div class="section4">
      <div class="contentWrapper">
        <div class="left">
          <img
            src="../assets/image/phoneShow.webp"
            style="position: absolute;"
            alt=""
          >
        </div>
        <div class="right">
          <div class="tmBox">
            <img
              src="../assets/image/tm.png"
              alt=""
            >
          </div>
          <h1>云混剪引擎</h1>
          <span class="title">
            云混剪引擎
            <p>业界领先的短视频量产技术</p>
          </span>
          <span class="count">1个人，1天拍摄，可以自动剪辑出上千的原创视频，满足您的顾客视频发送需求，帧帧原创，音画同步，卓越不凡。</span>
          <span class="subTitle">真正双引擎+</span>
          <span class="count">国内首创双引擎视频生产技术，两种剪辑方式，得到探店剧情类与VLOG分享类不同风格的视频，满足多种内容需求。</span>
          <a
            target="view_window"
            href="http://show.wxj360.com/mixed-demo/#/vertical"
            class="btnColor"
          >观看混剪作品</a>
          <div class="img"></div>
        </div>
      </div>
    </div>
    <div class="section5">
      <div class="contentWrapper">
        <div class="left">
          <h1>真实数据</h1>
          <span class="title">真实数据，尽在掌控<br>咨询加盟，一键回复</span>
          <span class="subTitle">每一条视频的播放、点赞、评论、转发数据均可在数据中心查看，每一条视频的评论咨询线索均可在线索中心查看并回复。<br> 我们很有可能不是流量最高的视频，但我们一定是数据最真实有效的推广渠道。</span>
          <div
            class="btnColor"
            @click="showFrameTostFunc()"
          >我要体验</div>
        </div>
        <div class="right">
          <img
            style="position: absolute;"
            src="../assets/image/people.svg"
            alt=""
          >
        </div>
      </div>
    </div>
    <div
      class="section6"
      id="section6"
    >
      <div class="contentWrapper">
        <p class="title">前台功能展示</p>
        <p class="subTitle">以下是部分功能截图、具体功能演示请扫上方二维码
          或联系客服索取方案以及更多演示。</p>
        <!-- 框框 -->
        <!-- 幻灯片 -->
        <div class="phone"></div>
        <swiper
          class="swiper video"
          :options="section6"
        >
          <swiper-slide class="videoItem">
            <div class="img function1"></div>
            <span>排行榜活动</span>
          </swiper-slide>
          <swiper-slide
            class="videoItem"
            style="width: width: 217px !important;"
          >
            <div class="img function2"></div>
            <span>锦鲤活动</span>
          </swiper-slide>
          <swiper-slide class="videoItem selected">
            <div class="img function3"></div>
            <span>扫码活动页</span>
          </swiper-slide>
          <swiper-slide class="videoItem">
            <div class="img function4"></div>
            <span>客户关怀</span>
          </swiper-slide>
          <swiper-slide class="videoItem">
            <div class="img function5"></div>
            <span>线索中心</span>
          </swiper-slide>
          <div
            class="swiper-pagination6"
            style="position: absolute;"
            slot="pagination"
          ></div>
        </swiper>
        <!-- 幻灯片结尾 -->
      </div>
    </div>
    <div class="section7">
      <div class="contentWrapper">
        <div class="item">
          <img
            src="../assets/image/icon_1.png"
            alt=""
          >
          <div class="right">
            <span class="title">1人扫码</span>
            <span class="subTitle">3元红包激励</span>
          </div>
        </div>
        <div class="item">
          <img
            src="../assets/image/icon_2.png"
            alt=""
          >
          <div class="right">
            <span class="title">500人观看</span>
            <span class="subTitle">平均数据</span>
          </div>
        </div>
        <div class="item">
          <img
            src="../assets/image/icon_3.png"
            alt=""
          >
          <div class="right">
            <span class="title">5人下单</span>
            <span class="subTitle">1%转化率</span>
          </div>
        </div>
        <div class="item">
          <img
            src="../assets/image/icon_4.png"
            alt=""
          >
          <div class="right">
            <span class="title">3人扫码</span>
            <span class="subTitle">裂变循环</span>
          </div>
        </div>
      </div>
    </div>
    <div class="section8">
      <div class="contentWrapper">
        <span class="title">我们的功能</span>
        <span class="subTitle">多达数十项功能创新<br />助您轻松跨入心动时代</span>
        <span class="subCount">历时3年时间，历经上万商家磨练，我们一直在探索<br />让每一个功能都对商家产生帮助是我们的初心。</span>
        <div class="count">
          <div class="item">
            <img
              src="../assets/image/icon/icon1.svg"
              alt=""
            >
            <span class="title">视频量产</span>
            <h1>视频量产</h1>
            <span class="subTitle">借助云混剪PLUS技术，1个人1天时间可以生产数千原创带货视频，完全杜绝视频搬运，让商家拥有足够的短视频弹药。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon2.svg"
              alt=""
            >
            <span class="title">视频发布</span>
            <h1>视频发布</h1>
            <span class="subTitle">抖音扫码，20秒即可完成视频发布，任何人在任何场景之下都可轻松发布一条抖音视频。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon3.svg"
              alt=""
            >
            <span class="title">店铺挂载</span>
            <h1>店铺挂载</h1>
            <span class="subTitle">视频中挂载商家的美团外卖小程序或抖音团购链接，观众可以快速下单，迅速转化，心动即购买。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon4.svg"
              alt=""
            >
            <span class="title">私域引流</span>
            <h1>私域引流</h1>
            <span class="subTitle">顾客在发布视频后，可以跳转到微信，快速将每一位客户沉淀到私域中，成为商家的私域达人资源，后续无限裂变。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon5.svg"
              alt=""
            >
            <span class="title">复购发券</span>
            <h1>复购发券</h1>
            <span class="subTitle">视频发布后，可在微信中发放美团优惠券，做为顾客发视频奖励，同时也促使顾客进行复购，卡券具有到期提醒，美团中直用的特点。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon6.svg"
              alt=""
            >
            <span class="title">二次营销</span>
            <h1>二次营销</h1>
            <span class="subTitle">系统还可以在微信中自动向顾客发送堂食优惠券、会员卡链接或者电商店铺链接，所有二次营销链接均可自定义，让顾客的一次互动转化更大价值。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon7.svg"
              alt=""
            >
            <span class="title">线索中心</span>
            <h1>线索中心</h1>
            <span class="subTitle">顾客发出去的每一个视频的评论，都会收集到商家后台，商家可做统一查看并回复，让商家不错过每一个购买&加盟意向客户。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon8.svg"
              alt=""
            >
            <span class="title">客户关怀</span>
            <h1>客户关怀</h1>
            <span class="subTitle">商家可自定义在用户参与活动后多少时间内给客户发一条消息，促进客户完成活动闭环，或者使用那张优惠券，增加品牌粘性与复购。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon9.svg"
              alt=""
            >
            <span class="title">超级@</span>
            <h1>超级@</h1>
            <span class="subTitle">商家可在系统中建立自己的用户库，每一次新视频发布，@4位老顾客，同时支持共享客源，商家之间相互@。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon10.svg"
              alt=""
            >
            <span class="title">客源大数据</span>
            <h1>客源大数据</h1>
            <span class="subTitle">每一位扫码的顾客，都会在系统中记录保存他的信息，如手机号，地理位置等信息，便于商家用作后续的客源交换。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon11.svg"
              alt=""
            >
            <span class="title">排行榜单</span>
            <h1>排行榜单</h1>
            <span class="subTitle">单商家或多商家参与的抖音达人挑战赛活动，顾客发布视频后自动参与，按照视频推广效果排名，开奖后集体发放奖励，可创建周期辅助私域。</span>
          </div>
          <div class="item">
            <img
              src="../assets/image/icon/icon12.svg"
              alt=""
            >
            <span class="title">抖音锦鲤</span>
            <h1>抖音锦鲤</h1>
            <span class="subTitle">单商家或多商家参与的抖音达人幸运抽奖活动，顾客发布视频后自动参与，按照抽奖号发放奖励，开奖自动发奖，可创建周期活动辅助私域运营。</span>
          </div>
        </div>
      </div>
    </div>
    <div class="section9">
      <div class="contentWrapper">
        <h1>代理</h1>
        <h1>购买</h1>
        <span class="title">购买 & 代理</span>
        <h1>提供多种合作方案</h1>
        <span class="subTitle">我们提供多种合作方案供您挑选<br />如果您没有找到满意的，请与我们联系</span>
        <div class="count">
          <div class="item">
            <span class="name">普惠版</span>
            <span class="money">￥3800.00</span>
            <span class="type">每店/每年</span>
            <hr>
            <span class="advantage"><img src="../assets/image/hook.png">包含产品码所有功能</span>
            <span class="advantage"><img src="../assets/image/hook.png">1 年技术支持</span>
            <span class="advantage"><img src="../assets/image/hook.png">基础数据统计</span>
            <button
              class="button"
              @click="showFrameTostFunc()"
            >现在开始</button>
          </div>
          <div class="item">
            <span class="name">专业版</span>
            <span class="money">￥5800.00</span>
            <span class="type">每店/每年</span>
            <hr>
            <span class="advantage"><img src="../assets/image/hook.png">包含产品码与私域码所有功能</span>
            <span class="advantage"><img src="../assets/image/hook.png">1 年技术支持</span>
            <span class="advantage"><img src="../assets/image/hook.png">高级数据统计+线索中心+客户关怀</span>
            <button
              class="button"
              @click="showFrameTostFunc()"
            >立即购买</button>
          </div>
          <div class="item">
            <span class="name">连锁合作/产品代理</span>
            <span class="money">请联系商务经理</span>
            <span class="type">代理名额有限，请尽早咨询</span>
            <hr>
            <span class="advantage"><img src="../assets/image/hook.png">产品培训</span>
            <span class="advantage"><img src="../assets/image/hook.png">技术支持</span>
            <span class="advantage"><img src="../assets/image/hook.png">全套资料</span>
            <button
              class="button"
              @click="showFrameTostFunc()"
            >立即联系</button>
          </div>
        </div>
      </div>
    </div>
    <div class="section10">
      <div class="contentWrapper">
        <h1>他们正在使用外卖爆单码</h1>
        <span class="title">他们正在使用外卖爆单码<br>听听他们的声音</span>
        <swiper
          style="height: 533px;"
          class="swiper count"
          :options="section10"
        >
          <swiper-slide class="item">
            <div class="bubbleBox">
              <span>八拖三老火锅是西南地区客单价最高的火锅外卖，单客客单价从50元-5000元（私宴）不等，通过让产品码替换掉之前的好评码，完善了平台违规风险，通知平均每天都能获得一定数量的抖音曝光，持续从抖音获得流量，单量从之前的每月2000单提升到3000单左右。</span>
            </div>
            <div class="business">
              <img
                src="../assets/image/buniss1.png"
                alt=""
              >
              <span class="name">八拖三老火锅</span>
              <span class="introduction">知名火锅外卖品牌</span>
            </div>
          </swiper-slide>
          <swiper-slide class="item">
            <div class="bubbleBox">
              <span>吮芝味韩式炸鸡是重庆海牛餐饮集团旗下炸鸡品牌，全国拥有数百家店铺，他们借助本系统持续在抖音获得曝光，让人们形成对品牌的深刻记忆，同时吸引更多城市的加盟商合作，在发出去的每一个视频都@了品牌的商务经理，有加盟意向的用户可轻松咨询合作事宜，同时使用线索中心对每一个视频的咨询信息进行统一回复，达到快速扩张效果。</span>
            </div>
            <div class="business">
              <img
                src="../assets/image/buniss2.png"
                alt=""
              >
              <span class="name">吮芝味韩式炸鸡</span>
              <span class="introduction">知名炸鸡外卖品牌</span>
            </div>
          </swiper-slide>
          <swiper-slide class="item">
            <div class="bubbleBox">
              <span>二娃卤味是一家社区卤菜店，原本以堂食为主，在使用外卖爆单码以后，单量从原本50单上升到了500单左右，每月稳定贡献营业额4万元左右，在私域码升级以后，二娃卤味正在将堂食与外卖客户引入私域，并持续在顾客社群开展抖音达人挑战赛与抖音锦鲤活动，获得稳定持续曝光与转化。</span>
            </div>
            <div class="business">
              <img
                src="../assets/image/buniss3.png"
                alt=""
              >
              <span class="name">二娃卤味</span>
              <span class="introduction">社区卤菜品牌</span>
            </div>
          </swiper-slide>
          <!-- <swiper-slide class="item">
            <div class="bubbleBox">
              <span>用户说信息文字示例内容最多显示五行，领导一个组织是令人难以置信的回报，同样令人谦卑。 自信和谦逊。 每一次成功都是建立在从错误中吸取教训的基础上的，都是令人难以置信的回报，同样令人谦卑。 它需要健康。</span>
            </div>
            <div class="business">
              <img src="../assets/image/buniss1.png" alt="">
              <span class="name">八拖三老火锅</span>
              <span class="introduction">知名火锅外卖品牌</span>
            </div>
          </swiper-slide> -->
          <div
            class="swiper-pagination10"
            style="position: absolute;text-align: center;line-height:290px;height: 190px;"
            slot="pagination"
          ></div>
        </swiper>
      </div>
    </div>
    <div class="section11">
      <div class="contentWrapper">
        <div class="left">
          <img
            style="position: absolute"
            src="../assets/image/notebook.png"
            alt=""
          >
          <!-- <swiper class="swiper slideshow" :options="section11">
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/1.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/2.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/3.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/4.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/5.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/6.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/7.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/8.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/9.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/10.jpeg" alt="">
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img class="img1" src="../assets/image/group/11.jpeg" alt="">
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white sec_11_pagination" slot="pagination"></div>
          </swiper> -->
          <swiper
            class="swiper slideshow"
            :options="section11"
          >
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/1.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/2.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/3.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/4.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/5.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/6.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/7.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/8.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/9.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/10.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/11.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/12.jpg"
                alt=""
              >
            </swiper-slide>
            <swiper-slide class="slide-1">
              <img
                class="img1"
                src="../assets/image/group2/13.jpg"
                alt=""
              >
            </swiper-slide>
            <div
              class="swiper-pagination swiper-pagination-white sec_11_pagination"
              slot="pagination"
            ></div>
          </swiper>
        </div>
        <div class="right">
          <h1>大波推，敢为天下先</h1>
          <h1>一直在落地，做一个有良心的服务商</h1>
          <span class="title">大波推，敢为天下先<br>一直在落地，做一个有良心的服务商</span>
          <span class="count">我们一直在帮助线下实体商家解决各种营销推广问题，在西南地区，我们服务了上千家实体商家，拥有丰富的落地实战经验。</span>
          <div class="li">
            <img
              src="../assets/image/icon01.png"
              alt=""
            >
            <div class="word">
              <span class="title">永久免费更新</span>
              <span class="count">随着技术团队的不断研发，我们会升级出更多新鲜有趣的功能，商家可以在服务期内免费更新使用。</span>
            </div>
          </div>
          <div class="li">
            <img
              src="../assets/image/icon02.png"
              alt=""
            >
            <div class="word">
              <span class="title">傻瓜式模块化后台</span>
              <span class="count">为方便商家客户使用，我们提供非常简单的模块化功能组件后台，仅需花费数分钟，即可配置完成。</span>
            </div>
          </div>
          <div class="li">
            <img
              src="../assets/image/icon03.png"
              alt=""
            >
            <div class="word">
              <span class="title">云端免费指导</span>
              <span class="count">我们提供了丰富的视频教程，以及1客1群的售后体系，7*8小时为商家提供软件使用、落地指导服务。</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="section12"
      id="section12"
    >
      <div class="contentWrapper">
        <h1>全国招募合作伙伴</h1>
        <span class="title">全国招募合作伙伴</span>
        <h1>合作</h1>
        <span class="phone">合作热线：17602390088</span>
        <div class="input">
          <input
            v-model="phone"
            placeholder="输入您的手机号"
          />
          <button
            class="btn"
            @click="getPhone"
          >获得免费体验</button>
        </div>
      </div>
    </div>
    <footer>
      <div class="contentWrapper">
        <div class="companyInfo item">
          <img
            src="../assets/image/logo_black.png"
            alt=""
          >
          <h1>大波推</h1>
          <span>我们致力软件研发行业多年，于2020年正式进军短视频SAAS服务领域，成为抖音开放平台、巨量千川开放平台、抖音开放平台服务市场官方服务商，旗下拥有数十款软件著作权，为重庆地区高新科技企业（申办中）。</span>
          <img
            src="../assets/image/thirdParty.png"
            alt=""
          >
        </div>
        <div class="certificate item">
          <img
            @click="disableImg(1)"
            src="../assets/image/certificate1.webp"
            alt="点击查看详情"
          >
          <img
            @click="disableImg(2)"
            src="../assets/image/certificate2.webp"
            alt="点击查看详情"
          >
          <img
            @click="disableImg(3)"
            src="../assets/image/certificate3.webp"
            alt="点击查看详情"
          >
          <img
            @click="disableImg(4)"
            src="../assets/image/certificate4.webp"
            alt="点击查看详情"
          >
          <!-- <img src="../assets/image/certificate1.png" alt="点击查看详情">
          <img src="../assets/image/certificate2.png" alt="点击查看详情">
          <img src="../assets/image/certificate3.png" alt="点击查看详情">
          <img src="../assets/image/certificate4.png" alt="点击查看详情"> -->
        </div>
        <div class="website">
          <span>网站</span>
          <a href="#section2">视频介绍</a>
          <a href="#section3">产品介绍</a>
          <a href="#section6">功能介绍</a>
          <a href="#section10">客户案例</a>
        </div>
        <div class="contactUs">
          <span>联系我们</span>
          <span>重庆市渝中区大坪街道英利国际中心2号44楼4号&5号</span>
          <span class="phone"><img src="../assets/image/call.png">电话：17602390088</span>
          <span class="email"><img src="../assets/image/mail.png">Email：pangxiekeji@wxj360.com</span>
        </div>
      </div>
      <div class="contentWrapper">
        <span style="margin-right:10px">© 2021 copyright All Rights Reserved.Crabsoft</span>
        <a href="http://beian.miit.gov.cn">
          <span>渝ICP备 15004422号-2</span>
        </a>
        <a href="http://www.http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010502002545">
          <img
            src="../assets/image/0c57706669.png"
            alt=""
          >
          <span>渝公网安备 50010502002545号</span>
        </a>
        <span></span>
      </div>
    </footer>
    <!-- 弹框 -->
    <div
      class="bulletFrame"
      v-show="showTos"
    >
      <span>{{ msg }}</span>
    </div>
    <div
      class="showImg"
      v-show="IsImg"
      @click="disableImg()"
    >
      <div class="imgBox">
        <img
          class="img1"
          v-if="srcIndex === 1"
          src="../assets/image/certificate1.webp"
        >
        <img
          class="img2"
          v-else-if="srcIndex === 2"
          src="../assets/image/certificate2.webp"
        >
        <img
          class="img3"
          v-else-if="srcIndex === 3"
          src="../assets/image/certificate3.webp"
        >
        <img
          class="img4"
          v-else
          src="../assets/image/certificate4.webp"
        >
      </div>
    </div>
    <div
      class="frame"
      v-show="showFrameTost"
    >
      <div class="boxBottom"></div>
      <div class="box">
        <div class="close">
          <img
            @click="showFrameTostFunc()"
            src="../assets/image/close.png"
            alt=""
          >
        </div>
        <span class="hint">您可以使用微信扫描以下二维码 <br> 与我们的工作人员取得联系</span>
        <div class="qr">
          <img
            src="../assets/image/newQr.png"
            alt=""
          >
        </div>
        <span class="hint">或致电我们</span>
        <div class="about">
          <span class="about">王经理 - </span>
          <span class="phoneNum">17602390088</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import Swiper1, { Autoplay, Pagination, EffectFade } from 'swiper'
Swiper1.use([Autoplay, Pagination, EffectFade])
export default {
  name: 'index',
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      section2: {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        pagination: {
          el: '.swiper-pagination2',
          clickable: true
        }
      },
      section3: {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        loop: true,
        indicatorDots: true,
        pagination: {
          el: '.swiper-pagination3',
          clickable: true
        }
      },
      section6: {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        loop: true,
        indicatorDots: true,
        initialSlide: 2, // 默认第三个
        slidesPerView: 5, // 一页显示几个
        spaceBetween: 32, // 距离
        centeredSlides: true, // 设置了会居中显示
        pagination: {
          el: '.swiper-pagination6',
          clickable: true
        }
      },
      section10: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 22, // 距离
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination10',
          clickable: true,
          bulletClass: 'swiper-pagination-bullet myBullet'
        }
      },
      section11: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        effect: 'fade',
        pagination: {
          el: 'sec_11_pagination',
          clickable: true
        }
      },
      phone: '',
      name: '',
      showFrameTost: false, // 显示开通的弹框
      IsImg: false,
      flag: true,
      showTos: false,
      msg: '提交成功',
      srcIndex: 0,
      showAutoplay: '',
      plateType: 0,
      can_play: true // 暂停/开始
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    goSaas () {
      window.location.href = 'https://heart.wkh04.top'
    },
    play (index) {
      switch (index) {
        case 1:
          // this.$refs.video.src = res.data.video_url
          // document.getElementById
          this.$refs.videoInfo1.play()
          this.can_play = false
          break
        case 2:
          // 暂停播放
          this.can_play = true
          this.$refs.videoInfo1.pause()
          break
      }
    },
    showFrameTostFunc () {
      this.showFrameTost = !this.showFrameTost
    },
    disableImg (index) { // 显示图片
      if (index) {
        this.srcIndex = index
      }
      this.IsImg = !this.IsImg
    },
    anchorPointClick (i) { // 点击锚点高亮
      this.flag = false
      switch (i) {
        case 0:
          this.flag = true
          this.plateType = 0
          break
        case 1:
          this.plateType = 1
          break
        case 2:
          this.plateType = 2
          break
        case 3:
          this.plateType = 3
          break
        default:
          break
      }
    },
    scrollBar () {
      let top = 0
      top = document.documentElement.scrollTop
      if (top >= 200) {
        this.flag = false
      } else {
        this.flag = true
      }

      if (top <= 200) {
        this.plateType = 0
      } else if (top >= 1600 && top < 2200) { // 视频介绍
        this.plateType = 1
      } else if (top >= 2200 && top < 3200) { // 产品体验
        this.plateType = 2
      } else if (top >= 5000 && top < 6100) { // 功能介绍s
        this.plateType = 3
      } else {
        this.plateType = 4
      }
    },
    getPhone () {
      const that = this
      if (this.phone !== '') {
        const data = {
          name: this.name,
          phone: this.phone
        }
        this.$api.post('https://test.wxj360.com/app/index.php?i=1&c=entry&m=crab_cloud_video&do=plugs&op=customer&fn=get_contact_way', data).then((res) => {
          if (res.data.code === 1) {
            this.msg = '提交成功'
            this.phone = ''
          } else {
            this.msg = res.data.msg
          }
          that.showFrame()
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.msg = '请填写手机号'
        that.showFrame()
      }
    },
    showFrame () {
      const that = this
      that.showTos = true
      setTimeout(() => {
        that.showTos = false
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
